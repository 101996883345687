import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '单据号', prop: 'oinv_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    prop: 'procurement_type',
    label: '采购类型',
    itemType: 'select',
    options: [
      { value: 0, label: '非进口' },
      { value: 1, label: '进口' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => {
      return val === 0 ? '非进口' : '进口';
    }
  },
  {
    prop: 'finance_status',
    label: '通知状态',
    itemType: 'select',
    options: [
      { value: '已通知', label: '已通知' },
      { value: '未通知', label: '未通知' },
      { value: '无需通知', label: '无需通知' },
      { value: '已退回', label: '已退回' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => {
      return val ? val : '暂无';
    }
  },
  {
    label: '发票日期',
    prop: 'oinv_date',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '开票供应商', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '发票金额', prop: 'oinv_invtotal', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '收票日期',
    prop: 'oinv_recdate',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '付款日期',
    prop: 'oinv_paydate',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '部门', prop: 'oinv_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '公司抬头', prop: 'oinv_cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '本币金额', prop: 'scon_rmbtotal', itemType: 'input', input: false, sortable: false, widthAuto: true },
  store.state.stff_name,
  store.state.create_time,
  store.state.status
];

export const syncDialogTableProperties = [
  { label: '发票号码', prop: 'oinv_actu_nos', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '开票供应商', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '发票金额', prop: 'oinv_invtotal', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '录入人', prop: 'stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
